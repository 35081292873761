<template>
  <div class="baseModalBox flex-column-center">
    <div class="modalContent">
      <slot></slot>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  name: 'BaseModal',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
@import './BaseModal';
</style>