<template>
  <div class="commentBar flex-row-center">
    <van-field
      class="themeReply flex-row-center"
      v-model="text"
      label=""
      :placeholder="placeholder"
      @focus="focusInput"
      @blur="blurInput"
    />
    <div
      class="thumbBox flex-row-center"
      v-show="!showBtn"
    >
      <img
        class="actionIcon"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon1.png"
        alt=""
        @click.stop="zan"
        v-show="!likedStatus"
      >
      <img
        class="actionIcon"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon2.png"
        alt=""
        @click.stop="zan"
        v-show="likedStatus"
      >
      <div class="zanNum">{{likeNum}}</div>
    </div>
    <div
      class="btnBox"
      v-show="showBtn"
      @click.stop="goReply"
    >發佈</div>
  </div>
</template>
<script type="text/ecmascript-6">
import { Toast } from 'vant'
import { addReply } from '../../api/service'
import { likePost, cancelLikePost } from '../../api/service'
import { likeComment, cancelLikeComment } from '../../api/service'

export default {
  name: 'CommentBar',
  props: {
    postId: {},
    rootId: {},
    replyId: {},
    replyItem: {},
    type: {
      type: String,
      default: ''
    },
    likeNum: {
      type: Number,
      default: 0
    },
    likeType: {
      type: String,
      default: ''
    },
    likedStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    placeholder() {
      if (this.type == 'replyUser') {
        return '回复' + this.replyItem.user.nickname + '：'
      }
      return '我來說兩句......'
    }
  },
  data() {
    return {
      text: '',
      showBtn: false
    }
  },
  methods: {
    zan() {
      switch (this.likeType) {
        case 'post':
          if (!this.likedStatus) {
            this.goLikePost()
          } else {
            this.goDisLikePost()
          }
          break

        case 'comment':
          if (!this.likedStatus) {
            this.goLikeComment()
          } else {
            this.goDisLikeComment()
          }
          break

        default:
          break
      }
    },
    focusInput() {
      this.showBtn = true
    },
    blurInput() {
      setTimeout(() => {
        this.showBtn = false
      }, 10)
    },
    goLikePost() {
      likePost(this.postId).then((res) => {
        if (res.code == 0) {
          this.$emit('refreshPost')
          Toast('點贊成功')
        }
      })
    },
    goDisLikePost() {
      cancelLikePost(this.postId).then((res) => {
        if (res.data.code == 0) {
          this.$emit('refreshPost')
          Toast('取消成功')
        }
      })
    },
    goLikeComment() {
      likeComment(this.replyId).then((res) => {
        if (res.code == 0) {
          this.$emit('refreshComment')
          Toast('點贊成功')
        }
      })
    },
    goDisLikeComment() {
      cancelLikeComment(this.replyId).then((res) => {
        if (res.data.code == 0) {
          this.$emit('refreshComment')
          Toast('取消成功')
        }
      })
    },
    goReply() {
      switch (this.type) {
        case 'post':
          addReply({
            post_id: parseInt(this.postId),
            content: this.text
          }).then((res) => {
            if (res.code == 0) {
              this.text = ''
              Toast('回复成功')
              this.$emit('replySuccess')
            }
          })
          break
        case 'comment':
          addReply({
            post_id: parseInt(this.postId),
            content: this.text,
            root_id: this.replyId
          }).then((res) => {
            if (res.code == 0) {
              this.text = ''
              Toast('回复成功')
              this.$emit('replySuccess')
            }
          })
          break
        case 'replyUser':
          addReply({
            post_id: parseInt(this.postId),
            content: this.text,
            root_id: this.replyId,
            target_user_id: this.replyItem.user.id
          }).then((res) => {
            if (res.code == 0) {
              this.text = ''
              Toast('回复成功')
              this.$emit('replySuccess')
            }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './CommentBar.scss';
</style>