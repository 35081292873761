<template>
  <div class="commentDetail flex-column-center">
    <div
      class="detailBox flex-column-center"
      v-if="commentDetail"
      @click="changeType('comment')"
    >
      <div class="userLine">
        <img
          class="userIcon"
          :src="commentDetail.user.avatar"
          alt=""
          @click="goUserInfo(commentDetail.user.id)"
        >
        <div class="userName">{{commentDetail.user.nickname}}</div>
        <div class="userTitle">{{commentDetail.user.official}}</div>

        <img
          @click="showActionModal(commentDetail.user.id,commentDetail.id,'comment')"
          class="postActionIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/moreIcon.png"
          alt=""
        >
      </div>
      <div class="postContent">{{commentDetail.content}}
      </div>
      <div class="divider"></div>
      <div class="postInfoLine flex-row-center">
        <img
          class="eye"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/commentIcon.png"
          alt=""
        >
        <div class="postInfoNum">{{commentDetail.replied_cnt}}</div>
        <img
          class="thumb"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon.png"
          alt=""
        >
        <div class="postInfoNum">{{commentDetail.liked_cnt}}</div>
        <div class="postTime">發佈於{{getTime(commentDetail.create_time)}}</div>
      </div>
    </div>
    <div class="commentBox flex-column-center">
      <div class="headLine flex-row-center">
        <img
          class="titleImg"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/commentTitle.png"
          alt=""
        >
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="initCommentReplyList"
      >
        <div
          class="commentItemBox"
          v-for="(item,index ) in list"
          :key="index"
          @click="changeType('replyUser',item)"
        >
          <div class="userLine flex-row-center">
            <img
              class="userIcon"
              :src="item.user.avatar"
              alt=""
              @click="goUserInfo(item.user.id)"
            >
            <div class="userInfoBox">
              <div class="userName">{{item.user.nickname}}
                <span v-if="item.target_user_nickname">回复 {{item.target_user_nickname}}</span>
              </div>
              <div class="userTitle">{{item.user.official}}</div>
            </div>
            <img
              @click="showActionModal(item.user.id,item.id,'comment')"
              class="moreIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/moreIcon.png"
              alt=""
            >
          </div>
          <div class="commentText">
            {{item.content}}
          </div>
          <div class="actionLine flex-row-center">
            <div class="commentTime">{{getTime(item.create_time)}}</div>
            <div class="flex-row-center">
              <img
                class="actionIcon"
                src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/commentIcon.png"
                alt=""
              >
              <div class="actionNum">{{item.replied_cnt}}</div>
              <img
                v-show="!item.liked"
                class="actionIcon"
                src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon.png"
                alt=""
                @click.stop="goLikeComment(item,index)"
              >
              <img
                v-show="item.liked"
                class="actionIcon"
                src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/redZanIcon.png"
                alt=""
                @click.stop="goDisLikeComment(item,index)"
              >
              <div class="actionNum">{{item.liked_cnt}}</div>
            </div>
          </div>
          <div class="commentDivider"></div>
        </div>
      </van-list>

    </div>
    <CommentBar
      v-if="commentDetail"
      :type="replyType"
      :rootId="rootId"
      :postId="postId"
      :replyId="replyId"
      :replyItem="replyItem"
      :likeNum="commentDetail.liked_cnt"
      :likedStatus="commentDetail.liked"
      likeType="comment"
      @replySuccess="replySuccess"
      @refreshComment="initData"
    ></CommentBar>
    <ActionModal ref="actionModal"></ActionModal>
  </div>
</template>
<script type="text/ecmascript-6">
import ActionModal from '../../components/actionModal/ActionModal.vue'
import CommentBar from '../../components/commentBar/CommentBar.vue'
import {
  getReplyDetail,
  getReplyList,
  likeComment,
  cancelLikeComment
} from '../../api/service'
import { getFormateTime } from '../../util/timeUtil'
import { Toast } from 'vant'

export default {
  name: 'CommentReply',
  components: {
    ActionModal,
    CommentBar
  },
  data() {
    return {
      replyId: '',
      postId: '',
      rootId: '',
      cursor: '',
      loading: false,
      finished: false,
      list: [],
      commentDetail: null,
      replyType: 'comment',
      replyItem: {}
    }
  },
  mounted() {
    this.replyId = this.$route.query.replyId
    this.postId = this.$route.query.postId
    this.rootId = this.$route.query.rootId

    this.initData()
  },
  methods: {
    initData() {
      getReplyDetail(this.replyId).then((res) => {
        if (res.code == 0) {
          this.commentDetail = res.data
        }
      })
      // this.initCommentReplyList()
    },
    initCommentReplyList() {
      getReplyList(this.postId, {
        cursor: this.cursor,
        page_size: 10,
        root_id: this.replyId
      }).then((res) => {
        if (res.code == 0) {
          this.cursor = res.data.cursor
          res.data.list.map((item) => {
            this.list.push(item)
          })
          this.loading = false
          if (!res.data.cursor) {
            this.finished = true
          }
        }
      })
    },
    changeType(type, item) {
      this.replyType = type
      this.replyItem = item
    },
    replySuccess() {
      this.cursor = ''
      this.list = []
      this.initCommentReplyList()
    },
    showActionModal(userId, id, type) {
      this.$refs.actionModal.showModal(userId, id, type)
    },
    getTime(time) {
      return getFormateTime(parseInt(time))
    },
    goUserInfo(id) {
      this.$router.push({
        path: 'Author',
        query: {
          userId: id
        }
      })
    },
    goLikeComment(item, index) {
      likeComment(item.id).then((res) => {
        if (res.code == 0) {
          this.list[index].liked = true
          this.list[index].liked_cnt++
          Toast('點贊成功')
        }
      })
    },
    goDisLikeComment(item, index) {
      cancelLikeComment(item.id).then((res) => {
        if (res.data.code == 0) {
          this.list[index].liked = false
          this.list[index].liked_cnt--
          Toast('取消成功')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './CommentReply.scss';
</style>