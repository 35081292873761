import http from './http.js';
import { root } from './http.js';
import axios from 'axios';
import { Toast } from 'vant';
import router from '../router';
import { myVue } from '../router';

// 处理content-type
const formateContentType = (params) => {
  let formData = new FormData();
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const element = params[key];
      formData.append(key, element);
    }
  }
  return formData;
};

axios.interceptors.response.use(
  (res) => {
    if (res.data.code !== 0) {
      Toast(res.data.msg);
    }
    if (res.data.code === 401) {
      try {
        localStorage.token = '';
        switch (window.location.hash) {
          case '#/mine':
            break;
          case '#/mall':
            break;

          default:
            router.push({ path: 'postIndex' });
            break;
        }

      } catch (error) {
        console.log(error);
      }
    }
    return res;
  },
  (err) => {
    // 对响应错误做些什么
    Toast(err);
    return Promise.reject(err);
  }
);

export const uploadFile = (params) => {
  return axios.post(root + '/upload', formateContentType(params), {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });
};

export const addPost = (params) => {
  return new Promise((resolve, reject) => {
    http.post('/post', params, (res) => {
      resolve(res);
    });
  });
};

export const postList = (params) => {
  return new Promise((resolve, reject) => {
    http.get('/post/list', params, (res) => {
      resolve(res);
    });
  });
};

export const postDetail = (params) => {
  return new Promise((resolve, reject) => {
    http.get('/post/detail/' + params, {}, (res) => {
      resolve(res);
    });
  });
};
export const getReplyList = (postId, params) => {
  return new Promise((resolve, reject) => {
    http.get('/reply/list/' + postId, params, (res) => {
      resolve(res);
    });
  });
};
export const getReplyDetail = (replyId, params) => {
  return new Promise((resolve, reject) => {
    http.get('/reply/' + replyId, params, (res) => {
      resolve(res);
    });
  });
};

export const voteDetail = (params) => {
  return new Promise((resolve, reject) => {
    http.get('/post/vote/' + params, {}, (res) => {
      resolve(res);
    });
  });
};

export const goVote = (id, params) => {
  return new Promise((resolve, reject) => {
    http.post('/post/vote/' + id, params, (res) => {
      resolve(res);
    });
  });
};
export const likePost = (id) => {
  return new Promise((resolve, reject) => {
    http.post('/post/like/' + id, {}, (res) => {
      resolve(res);
    });
  });
};
export const cancelLikePost = (id) => {
  return axios.delete(
    root + '/post/like/' + id,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
  );
};
export const likeComment = (id) => {
  return new Promise((resolve, reject) => {
    http.post('/reply/like/' + id, {}, (res) => {
      resolve(res);
    });
  });
};
export const cancelLikeComment = (id) => {
  return axios.delete(
    root + '/reply/like/' + id,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
  );
};
export const addReply = (params) => {
  return new Promise((resolve, reject) => {
    http.post('/reply', params, (res) => {
      resolve(res);
    });
  });
};

export const userInfo = () => {
  return new Promise((resolve, reject) => {
    http.get('/user/info', {}, (res) => {
      resolve(res);
    });
  });
};

export const getTaskInfo = () => {
  return new Promise((resolve, reject) => {
    http.get('/benefit/task', {}, (res) => {
      resolve(res);
    });
  });
};

export const goSignIn = () => {
  return new Promise((resolve, reject) => {
    http.post('/benefit/signin', {}, (res) => {
      resolve(res);
    });
  });
};

export const getUserPost = (userId, params) => {
  return new Promise((resolve, reject) => {
    http.get('/user/post/' + userId, params, (res) => {
      resolve(res);
    });
  });
};

export const getUserInfo = (userId) => {
  return new Promise((resolve, reject) => {
    http.get('/user/info/' + userId, {}, (res) => {
      resolve(res);
    });
  });
};

export const getBanner = () => {
  return new Promise((resolve, reject) => {
    http.get('/banner', {}, (res) => {
      resolve(res);
    });
  });
};

export const getTop = () => {
  return new Promise((resolve, reject) => {
    http.get('/post/top', {}, (res) => {
      resolve(res);
    });
  });
};

export const getExchangeList = () => {
  return new Promise((resolve, reject) => {
    http.get('/benefit/shop/item', {}, (res) => {
      resolve(res);
    });
  });
};
export const exchangeItem = (id) => {
  return new Promise((resolve, reject) => {
    http.post('/benefit/shop/exchange/' + id, {}, (res) => {
      resolve(res);
    });
  });
};

export const goSearchPost = (params) => {
  return new Promise((resolve, reject) => {
    http.post('/post/search', params, (res) => {
      resolve(res);
    });
  });
};

export const report = (params) => {
  return new Promise((resolve, reject) => {
    http.post('report', params, (res) => {
      resolve(res);
    });
  });
};
export const followUser = (userId) => {
  return new Promise((resolve, reject) => {
    http.post('/user/follow/' + userId, {}, (res) => {
      resolve(res);
    });
  });
};
export const unFollowUser = (userId) => {
  return axios.delete(
    root + '/user/follow/' + userId,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
  );
};
export const getFollowList = (params) => {
  return new Promise((resolve, reject) => {
    http.get('/user/follow', params, (res) => {
      resolve(res);
    });
  });
};
export const getFansList = (params) => {
  return new Promise((resolve, reject) => {
    http.get('/user/fan', params, (res) => {
      resolve(res);
    });
  });
};
export const getMessageList = (params) => {
  return new Promise((resolve, reject) => {
    http.get('/user/reply', params, (res) => {
      resolve(res);
    });
  });
};
export const getReward = (id) => {
  return new Promise((resolve, reject) => {
    http.post('/benefit/task/reward/'+id, {}, (res) => {
      resolve(res);
    });
  });
};
