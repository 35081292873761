<template>
  <div v-if="isShow">
    <BaseModal>
      <div class="modalBox flex-column-center">
        <div class="actionItem">選擇舉報理由</div>
        <div class="reasonBox flex-row-center">{{reasonText}}
          <van-icon
            v-if="!showOptions"
            name="arrow-down"
            @click="showOptions=true"
          />
          <van-icon
            v-if="showOptions"
            name="arrow-up"
          />
        </div>
        <div
          class="reasonList"
          v-show="showOptions"
        >
          <div
            class="reasonItem"
            v-for="(item,index) in reasonList"
            :key="index"
            @click="chooseReason(item)"
          >{{item.text}}</div>
        </div>
        <div class="blank"></div>

        <div class="divider"></div>
        <div
          class="actionItem"
          @click="goReport"
        >確定舉報</div>
        <div
          class="actionItem"
          @click="closeModal"
        >取消</div>
      </div>
    </BaseModal>
  </div>
</template>
<script type="text/ecmascript-6">
import BaseModal from '../baseModal/BaseModal.vue'
import { report } from '../../api/service'
import { Toast } from 'vant'
export default {
  name: 'ActionModal',
  components: {
    BaseModal
  },
  computed: {},
  data() {
    return {
      isShow: false,
      userId: null,
      localUserId: localStorage.userId,
      id: null,
      type: null,
      showOptions: false,
      reasonList: [
        {
          text: '其他違反社區版規行為',
          id: 0
        },
        {
          text: '色情淫穢、血腥暴力內容',
          id: 1
        },
        {
          text: '攻擊引斬、不友善行為',
          id: 2
        },
        {
          text: '造謠、造假行為',
          id: 3
        },
        {
          text: '灌水、帶節奏、不當刷屏等行為',
          id: 4
        }
      ],
      reasonId: 0,
      reasonText: '其他違反社區版規行為',
      typeMap: {
        post: 1,
        comment: 2
      }
    }
  },
  methods: {
    showModal(userId, id, type) {
      this.isShow = true
      this.userId = userId
      this.id = id
      this.type = type
    },
    goReport() {
      report({
        type: this.typeMap[this.type],
        target_id: this.id,
        reason_id: this.reasonId
      }).then((res) => {
        if (res.code == 0) {
          Toast('举报成功')
          this.closeModal()
        } else {
          Toast(res.msg)
          this.closeModal()
        }
      })
    },
    closeModal() {
      this.isShow = false
    },
    goDeletePost() {},
    chooseReason(item) {
      this.reasonId = item.id
      this.reasonText = item.text
      this.showOptions = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import './ActionModal.scss';
</style>